import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { global } from './global';
@Injectable({
  providedIn: 'root'
})
export class PackingService {
  public url:string
  constructor(
    public _http:HttpClient
  ) {
    this.url=global.url
  }
  packing(token,datos):Observable<any>{
    let json=JSON.stringify(datos);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                .set('Auth',token)
    return this._http.post(this.url+'packing',params,{headers:headers});
  }
  getcanastas():Observable<any>{

    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')

    return this._http.get(this.url+'canastas/getall',{headers:headers});
  }
  getparametrosUsuario(token,datos):Observable<any>{
    let json=JSON.stringify(datos);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
      .set('Auth',token)
    return this._http.post(this.url+'packingparametro/getparametros_usuario',params,{headers:headers});
  }
}
