import { Component, OnInit,ViewChild } from '@angular/core';
import { LoadingController, MenuController, ModalController, ToastController, ToastOptions } from '@ionic/angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ParametrosConsumo } from 'src/app/models/ParametrosConsumo';
import { Parametros } from 'src/app/models/parametros';
import { PersonalizarArray } from 'src/app/models/personalizarArray';
import { AplicacionAgricolaService } from 'src/app/services/aplicacionAgricola.service';
import { PackingService } from 'src/app/services/packing.service';
import { SemanasService } from 'src/app/services/semanas.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexResponsive
} from "ng-apexcharts";
import { Router } from '@angular/router';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions:ApexPlotOptions;
  stroke:ApexStroke;
  tooltip:ApexTooltip;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  responsive:ApexResponsive
};

@Component({
  selector: 'app-completa',
  templateUrl: './completa.component.html',
  styleUrls: ['./completa.component.css']
})
export class CompletaComponent implements OnInit {
  public selectedCar: String;
  public selectedCar2: any[]=[];
  public selectedCar3: any;
  public consumosArray:any[]=[];
  public especiesArray:any[]=[];
  public parametros: Parametros;
  public parametrosconsumo: ParametrosConsumo;
  public parametrosArray;
  private personalizar:PersonalizarArray
  public token
  public usuario
  public semanas:any[]=[];
  public loading;
  public cadena:string;
  private dataArray:any
  public selectedCar4:any[]=[]
  chart: ApexCharts;
  chart1: ApexCharts;
  public showtabla
  public listsemanas:string
  public showgrafico
  //chartOptions: any;
  chartSeries: any[];
  ////////

  @ViewChild("chart") chartwalmer: ChartComponent;
  public chartOptions: Partial<ChartOptions> |any;
  public chartOptions2: Partial<ChartOptions> |any;

  ////////
  constructor(
    private config: NgSelectConfig,
    private toast:ToastController,
    private usuarioService:UsuarioService,
    private packingService:PackingService,
    private semanaService:SemanasService,
    private aplicacionAgricola:AplicacionAgricolaService,
    private loadingCtrl: LoadingController,
    private modalCtrl:ModalController,
    private menuCtrl:MenuController,
    private _router:Router,
  ) {
    this.token=this.usuarioService.gettoken();
    this.usuario=localStorage.getItem('usuario');
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
    this.showgrafico=false
    this.showtabla=true
    this.chartOptions = {
      series:[{data:[]},{data:[]}],
      chart: {
        type: 'bar',
        height: 100
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },

      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      responsive: [{
        //breakpoint: height, // Punto de quiebre para la responsividad
        options: {
          chart: {
            height: 'auto' // Altura automática para adaptarse a diferentes tamaños de pantalla
          }
        }
      }]

    };
    this.chartOptions2={
      series: [
        {
          name:'programado',
          data: [0],
        }, {
          name:'consumido',
          data: [0],
        }
      ],
      chart: {
        type: 'bar',
        height: 500
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        }
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: [''],
      },
    }

  }

  ngOnInit() {
    this.listarSemanas()
    this.llarmarParametrosUsurio()
    this.showgrafico=false
    this.showtabla=true
  }
  cerrarsesion(){
    localStorage.removeItem('idapp');
    localStorage.removeItem('token');
    localStorage.removeItem('cod_emp');
    localStorage.removeItem('cod_tem');
    localStorage.removeItem('usuario');
    localStorage.removeItem('perfil');
    this.menuCtrl.close('first');
    //Redireccion a la pagina principal
    this._router.navigate(['/home']);

  }
  llarmarParametrosUsurio(){
    this.parametros=new Parametros(this.usuarioService.getEmpresa(),this.usuarioService.getTemporada(),this.usuarioService.getusuario())
    this.packingService.getparametrosUsuario(this.token,this.parametros).subscribe(
      response=>{
        if(response.data.length>0){

          this.parametrosArray=response.data;
          this.selectedCar=this.parametrosArray[0].COD_PRO;
          this.cargarSemana()
          //this.mostrarEspecie('-1')

          //hola
        }else{
          this.presentToast({
            duration: 3000,
            message: "Usuario sin parametros",
            buttons: [
              { text: 'Cerrar'}
            ]
          });
        }

      },error=>{
        this.presentToast({
          duration: 3000,
          message:error.toString(),
          buttons: [
            { text: 'Cerrar'}
          ]
        });
      }
    );
  }
  async presentToast(opts: ToastOptions) {
    const toast = await this.toast.create(opts);
    await toast.present();
  }
  private listarSemanas(){
    let semana;
    for (let i = 0; i < 52; i++) {
      semana = {
        id: (i + 1).toString(),
        semana: "SEMANA " + (i + 1).toString()
      };
      this.semanas.push(semana);
    }
  }
  private cargarSemana() {
    let parametro={'COD_EMP':this.usuarioService.getEmpresa(),'COD_TEM':this.usuarioService.getTemporada()}
    this.semanaService.getsemana(this.token,parametro).subscribe(
      response=>{
        if(response.data.length>0){
          this.selectedCar2=[response.data[0].SEMANA.toString()];
          this.obtenerValores(this.selectedCar2)
        }

      },error=>{
        this.presentToast({
          duration: 3000,
          message:error.toString(),
          buttons: [
            { text: 'Cerrar'}
          ]
        });
      }
    );
  }
  async obtenerValores(semana:any) {

    this.consumosArray=[]
    this.loading = await this.loadingCtrl.create({
      message: 'Cargando tabla de consumos ...',
    });

    this.loading.present();
    this.listsemanas=''
    semana.forEach((dato)=>{
      this.listsemanas+=dato+','
    })
    this.listsemanas=this.listsemanas.substring(0,this.listsemanas.length-1)

    let parametrosconsumo={
      COD_EMP:this.usuarioService.getEmpresa(),
      COD_TEM:this.usuarioService.getTemporada(),
      COD_PRO:this.selectedCar.toString(),
      SEMANA:this.listsemanas,
      OPCION:'TODOS'
    };
    this.aplicacionAgricola.getconsumosemanal_completa(this.token,parametrosconsumo).subscribe(
        async response=>{
          if(response.code==200){
            this.personalizarjson(response.data);

            this.loading.dismiss()
            this.especiesArray=response.especies
            this.especiesArray.unshift({ESPECIE:'TODOS'})
            this.selectedCar3='TODOS';
          }else{
            this.loading.dismiss()
            this.presentToast({
              duration: 3000,
              message: "No hay datos para mostrar",
              buttons: [
                { text: 'Cerrar'}
              ]
            });
          }
        },async error=>{
          this.loading.dismiss()
          this.presentToast({
            duration: 3000,
            message: error.mensaje,
            buttons: [
              { text: 'Cerrar'}
            ]
          });
      }
    );
  }
  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Cargando tabla de consumos ...',
      cssClass: 'custom-loading',
    });

    this.loading.present();
  }
  private personalizarjson(array:any) {
    this.consumosArray=[]
    this.personalizar=new PersonalizarArray();
    this.personalizar.value=array[0].CUARTEL;
    let cont=1;
    array.forEach((consumo,index)=>{
      const cuartel=consumo.CUARTEL;
      if(this.personalizar.value!==cuartel){
        cont++
        this.consumosArray.push(this.personalizar)
        this.personalizar=new PersonalizarArray();
        this.personalizar.value=cuartel;
      }
      this.personalizar.data.push(consumo)
      if(index==array.length-1){
        this.consumosArray.push(this.personalizar)
      }
    });
    if(!this.showtabla){
      this.mostrarcuartelatabla()
    }
    if(!this.showgrafico){
      this.programado_consumido_hectareas()
      this.mostrarbarras()
    }
  }
  async generarHTML(data1:any) {
    let ban=true;
    if(data1.data.length<=1){
      this.cadena+='<tr class="table-bordered border-dark text-center pe-2 ps-2">\n<td><p>'+data1.value+'</p></td>\n';
    }else{
      this.cadena+='<tr class="table-bordered border-dark text-center pe-2 ps-2">';
      this.cadena+='<td rowspan="'+data1.data.length+'"><p>'+data1.value+'</p></td>\n';
      ban=false;
    }
    data1.data.forEach(data2 => {
      if(data1.data.length>1&&ban){
        this.cadena+='<tr class="table-bordered border-dark text-center pe-2 ps-2">\n';
      }else{
        ban=true;
      }
      this.cadena+='<td ><p>'+data2.PRODUCTO+ ' - '+data2.MEDIDA+'</p></td>\n';
      this.cadena+='<td ><p>'+Number(data2.TOTAL_CONSUMO).toFixed(2)+'</p></td>\n';
      this.cadena+='<td ><p>'+Number(data2.TOTAL_PROGRAMADO).toFixed(2)+'</p></td>\n';
      this.cadena+='<td ><p>'+Number(data2.HAS).toFixed(2)+'</p></td></tr>\n';
    })
  }
  async consultarespecie(especie:any){
    let parametrosconsumo={
      COD_EMP:this.usuarioService.getEmpresa(),
      COD_TEM:this.usuarioService.getTemporada(),
      COD_PRO:this.selectedCar.toString(),
      SEMANA:this.listsemanas,
      OPCION:'PORESPECIE',
      ESPECIE:especie
    };

    this.loading = await this.loadingCtrl.create({
      message: 'Cargando por especie ...',
    });

    this.loading.present();
    this.aplicacionAgricola.getconsumosemanal_completa(this.token,parametrosconsumo).subscribe(
        async response=>{
          if(response.data.length>0){
            this.personalizarjson(response.data);
            this.loading.dismiss()
          }else{
            this.presentToast({
              duration: 3000,
              message: "No hay datos para mostrar",
              buttons: [
                { text: 'Cerrar'}
              ]
            });
          }
        },async error=>{
          this.loading.dismiss()
          this.presentToast({
            duration: 3000,
            message: error.mensaje,
            buttons: [
              { text: 'Cerrar'}
            ]
          });
      }
    );
  }
  async mostrarcuartelatabla(){
    this.cadena=''
    document.getElementById("agregarcodigo").innerHTML=''  //falta cooregir lo de cuando el event esta vacio
    this.consumosArray.forEach(data1=>{
      this.generarHTML(data1)
    })
    if(this.cadena==''){
      this.cadena+='<td colspan="6" class="table-bordered border-dark text-center"><p>No se encontro registros</p></td>'
    }
    let cabecera='<tr class="table-info text-center"><th scope="col">CUARTEL</th><th scope="col">PRODUCTO</th><th scope="col">CONSUMIDO</th><th scope="col">PROGRAMADO</th><th scope="col">HAS</th></tr>'
    this.cadena=cabecera+this.cadena;
    document.getElementById("agregarcodigo").innerHTML =this.cadena;
    if (this.loading && this.loading.dismiss) {
      this.loading.dismiss()
    }

  }
  async mostrartabla(){
    this.showgrafico=true;
    this.showtabla=false
    this.obtenerValores(this.selectedCar2)
    //this.mostrarcuartelatabla()
    
    //document.getElementById(".tabla").display.None
  }
  async mostrargrafico(){
    this.showgrafico=false;
    this.showtabla=true
    
    this.obtenerValores(this.selectedCar2)
    
  }
  async programado_consumido_hectareas(){
    let sum=0;let sum2=0;let sum3=0;
    this.consumosArray.forEach((array)=>{
      array.data.forEach(valor=>{
        sum=sum+parseFloat(valor.TOTAL_CONSUMO)
        sum2=sum2+parseFloat(valor.TOTAL_PROGRAMADO)
        sum3=sum3+parseFloat(valor.HAS)

      })
    })
    
    this.chartOptions2.series=[
      {
        name:'programado',
        data: [Number(sum.toFixed(2))],
      }, {
        name:'consumido',
        data: [Number(sum2.toFixed(2))],
      }
    ]

  }
  async mostrarbarras(){
    
    let programado=[]
    let consumido=[]
    let hectareas=[]
    let variedades=[]
    let height=150
    let nom_cua;
    this.consumosArray.forEach((data) =>{
      height+=40
      let sum=0;let sum2=0;let sum3=0
      data.data.forEach(valor=>{
        sum=sum+parseFloat(valor.TOTAL_CONSUMO)
        sum2=sum2+parseFloat(valor.TOTAL_PROGRAMADO)
        sum3=sum3+parseFloat(valor.HAS)

      })
      programado.push(Number(sum.toFixed(2)))
      consumido.push(Number(sum2.toFixed(2)))
      hectareas.push(Number(sum3.toFixed(2)))
      nom_cua=data.value.substring(0,data.value.indexOf('-')+1)
      variedades.push(nom_cua)
    })
    this.barras(programado,consumido,variedades,hectareas,height)
  }
  barras(programado:any,consumido:any,variedades:any,hectareas:any,height:number){
    this.chartOptions.chart.height=height
    this.chartOptions.xaxis ={
      categories:variedades
    }
    //this.chartOptions.series.data = programado
    this.chartOptions.series =[{
      name:'programado',
      data: programado,
    }, {
      name:'consumido',
      data: consumido,
    }]

  }
}
