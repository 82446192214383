import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class SemanasService {
  public url:string
  constructor(
    public _http:HttpClient,

  ) {
    this.url=global.url;
  }
  getsemana(token,temporada):Observable<any>{
    let json=JSON.stringify(temporada);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                .set('Auth',token)
    return this._http.post(this.url+'semanas/getsemana',params,{headers:headers});
  }
}