// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapsKey:'pk.eyJ1IjoidmVyZnJ1dHBlcnUiLCJhIjoiY2wzNjRjdnlwMGMwejNrb3o4c2dzenB5ZyJ9.4bg-eMUfJTwhwJzLlfm7tw',
  rondinKey:'pk.eyJ1IjoidmVyZnJ1dGFwcCIsImEiOiJjbDRhNm00c3YwOW5mM2txZ2xscnQxajBuIn0.ThXJLgq1p_bzD2FxVxMunw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
