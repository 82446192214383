export class Parametros
{
  constructor(
    public COD_EMP: string,
    public COD_TEM: string,
    public USUARIO:string,
  ){

  }
}
