import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectSearchModule } from 'mat-select-search';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MaterialModule } from './material.module';
import { CacheComponent } from './cache/cache.component';
import { MainEstimacPage } from './AppEstimac/main-estimac/main-estimac.page';
import { MainEstimacPageModule } from './AppEstimac/main-estimac/main-estimac.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CompletaModule } from './AppEstimac/completa/completa.module';
import { CompletaRoutingModule } from './AppEstimac/completa/completa-routing.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents:[
    
  ],

  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSelectSearchModule,
    MaterialModule,
    NgApexchartsModule,
    CompletaRoutingModule,
    //GraficosPageModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner,
    Camera,
    FileTransfer,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
