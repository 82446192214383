import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { global} from './global';
import { JwtHelperService } from "@auth0/angular-jwt";
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  public url:string;
  public token:string;
  public empresa:string;
  public temporada:string;
  public modulo:string;
  public pais:string;
  public IDUSUARIO;
  public helper=new JwtHelperService();
  constructor(
    public _http: HttpClient,
  ) {
    this.url=global.url;
   }

  signup(user): Observable<any>{
    let json=JSON.stringify(user);
    let params='json='+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post(this.url+'login',params,{headers:headers});
  }
  obtenerempresa(token):Observable<any>{
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                  .set('Auth',token);
    return this._http.get(this.url+'empresa',{headers:headers})
  }

  obtenertemporada(token,Empresa):Observable<any>{
    let json=JSON.stringify(Empresa);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                  .set('Auth',token);
    return this._http.post(this.url+'temporada',params,{headers:headers})
  }
  obtenermodulos(token):Observable<any>{
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                .set('Auth',token)
    return this._http.get(this.url+'perfil/aplicaciones',{headers: headers})
  }
  getcountry(lat,long):Observable<any>{
    return this._http.get('http://api.geonames.org/countryCodeJSON?formatted=true&lat='+lat+'&lng='+long+'&username=calbanc&style=full');
  }

  getpermisos(token,parametros):Observable<any>{
    let json=JSON.stringify(parametros);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
                                  .set('Auth',token);
    return this._http.post(this.url+'perfil/sistema',params,{headers:headers});
  }

  gettoken(){
    let token=localStorage.getItem('token');
    if(token && token != "undefined"){
      this.token=token;
    }else{
      this.token=null;
    }
    return this.token;
  }

  getPais(){
    let pais=localStorage.getItem('pais');
    if(pais && pais != "undefined"){
        this.pais=pais;
    }else{
      this.pais=null;
    }
    return this.pais;
  }

  getEmpresa(){
    let empresa=localStorage.getItem('cod_emp');
    if(empresa && empresa != "undefined"){
        this.empresa=empresa;
    }else{
      this.empresa=null;
    }
    return this.empresa;
  }
  getTemporada(){
      let temporada=localStorage.getItem('cod_tem');
      if(temporada && temporada != "undefined"){
        this.temporada=temporada;
      }else{
        this.temporada=null;
      }
      return this.temporada;
  }
  getmodulo(){
    let modulo=localStorage.getItem('idapp');
    if(modulo && modulo != "undefined"){
      this.modulo=modulo;
    }else{
      this.modulo=null;
    }
    return this.modulo;

  }

  checktoken(){
    const token=localStorage.getItem('token');
    return !this.helper.isTokenExpired(token);
  }
  getusuario(){
    let usuario=localStorage.getItem('usuario');
    if(usuario && usuario != "undefined"){
      this.IDUSUARIO=usuario;
    }else{
      this.IDUSUARIO=null;
    }
    return this.IDUSUARIO;

  }

  getpaisempresa(parametros):Observable<any>{
    let json=JSON.stringify(parametros);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')

    return this._http.post(this.url+'empresa/getpaisempresa',params,{headers:headers});
  }

}
