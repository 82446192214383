import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { global } from './global';
@Injectable({
  providedIn: 'root'
})
export class AplicacionAgricolaService {
  public url:string
  constructor(
    public _http:HttpClient
  ) {
    this.url=global.url;
  }
  getconsumosemanal(token,data):Observable<any>{
    let json=JSON.stringify(data);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
      .set('Auth',token)
    return this._http.post(this.url+'androidtitordenaplicacionagricola/getconsumosemanal',params,{headers:headers});
  }
  getconsumosemanal_completa(token,data):Observable<any>{
    let json=JSON.stringify(data);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
      .set('Auth',token)
    return this._http.post(this.url+'androidtitordenaplicacionagricola/getconsumosemanal_completa',params,{headers:headers});
  }
  getconsumosemanal_main(token,data):Observable<any>{
    let json=JSON.stringify(data);
    let params="json="+json;
    let headers=new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
      .set('Auth',token)
    return this._http.post(this.url+'androidtitordenaplicacionagricola/consumosemanalmain',params,{headers:headers});
  }
}
