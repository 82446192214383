import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Menu } from '../interface/menu';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private _http: HttpClient,
  ) { }
  getMenuOptsMateriales(){
    return this._http.get<Menu[]>('/assets/data/materiales-menu-opts.json');
  }

}
