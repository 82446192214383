import { Component,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuService } from './services/menu.service';
import { Menu } from './interface/menu';
import { IonRouterOutlet, MenuController } from '@ionic/angular';
import {Router,ActivatedRoute,Params} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 // componentes:Observable<Menu[]>
  public opciones=JSON.parse(localStorage.getItem('perfil'));
  public idaplicacion=localStorage.getItem('idapp')
  constructor(
    private menuService:MenuService,
    private _route:ActivatedRoute,
    private _router:Router,
    private menuCtrl:MenuController,

  ) {
    this.opciones=JSON.parse(localStorage.getItem('perfil'));
  }
  ngOnInit() {
    
    this.opciones=JSON.parse(localStorage.getItem('perfil'));
    
    //this.updateCache()
  }
  ngDoCheck(){
    this.opciones=JSON.parse(localStorage.getItem('perfil'));
  }
  cerrarsesion(){

    localStorage.removeItem('idapp');
    localStorage.removeItem('token');
    localStorage.removeItem('cod_emp');
    localStorage.removeItem('cod_tem');
    localStorage.removeItem('usuario');
    localStorage.removeItem('perfil');
    this.menuCtrl.close('first');
    //Redireccion a la pagina principal
    this._router.navigate(['/home']);

  }

}
