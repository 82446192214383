
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'aprueba-rendicion',
    loadChildren: () => import('./AppMateriales/aprueba-rendicion/aprueba-rendicion.module').then( m => m.ApruebaRendicionPageModule)
  },
  {
    path: 'main-materiales-page',
    loadChildren: () => import('./AppMateriales/main-materiales-page/main-materiales-page.module').then( m => m.MainMaterialesPagePageModule)
  },
  {
    path: 'rendicion',
    loadChildren: () => import('./AppMateriales/rendicion/rendicion.module').then( m => m.RendicionPageModule)
  },
  {
    path: 'detallerendicion',
    loadChildren: () => import('./AppMateriales/detallerendicion/detallerendicion.module').then( m => m.DetallerendicionPageModule)
  },
  {
    path: 'misrendiciones',
    loadChildren: () => import('./AppMateriales/misrendiciones/misrendiciones.module').then( m => m.MisrendicionesPageModule)
  },
  {
    path: 'detallemisrendiciones',
    loadChildren: () => import('./AppMateriales/detallemisrendiciones/detallemisrendiciones.module').then( m => m.DetallemisrendicionesPageModule)
  },
  {
    path: 'main-remuneraciones-page',
    loadChildren: () => import('./AppRemu/main-remuneraciones-page/main-remuneraciones-page.module').then( m => m.MainRemuneracionesPagePageModule)
  },
   {
     path: 'generaplanilla',
     loadChildren: () => import('./AppRemu/generaplanilla/generaplanilla.module').then( m => m.GeneraplanillaPageModule)
   }
   ,
  {
    path: 'detalleplanilla',
    loadChildren: () => import('./AppRemu/detalleplanilla/detalleplanilla.module').then( m => m.DetalleplanillaPageModule)
  }
   ,
   {
     path: 'listadoplanilla',
     loadChildren: () => import('./AppRemu/listadoplanilla/listadoplanilla.module').then( m => m.ListadoplanillaPageModule)
   }
  ,
  {
    path: 'opciones',
    loadChildren: () => import('./opciones/opciones/opciones.module').then( m => m.OpcionesPageModule)
  },
  {
    path: 'registra-camion',
    loadChildren: () => import('./AppRemu/RecepcionCamiones/registra-visita/registra-visita.module').then( m => m.RegistraCamionPageModule)
  },
  {
    path:'listado-camiones',
    loadChildren: () => import('./AppRemu/RecepcionCamiones/listado-camiones/listado-camiones.module').then( m => m.ListadoCamionesPageModule)
  },
  {
    path: 'main-directorio',
    loadChildren: () => import('./AppDirectorio/main-directorio/main-directorio.module').then( m => m.MainDirectorioPageModule)
  },
  {
    path: 'produccion',
    loadChildren: () => import('./AppDirectorio/produccion/produccion.module').then( m => m.ProduccionPageModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('./AppRemu/RecepcionCamiones/qr/qr.module').then( m => m.QrPageModule)
  },
  {
    path: 'marcacionesmaps',
    loadChildren: () => import('./AppRemu/marcacionesmaps/marcacionesmaps.module').then( m => m.MarcacionesmapsPageModule)
  },
   {
     path: 'planillasregistradas',
     loadChildren: () => import('./AppRemu/planillasregistradas/planillasregistradas.module').then( m => m.PlanillasregistradasPageModule)
   }
   ,
  {
    path: 'detalle-registro-planilla',
    loadChildren: () => import('./AppRemu/detalle-registro-planilla/detalle-registro-planilla.module').then( m => m.DetalleRegistroPlanillaPageModule)
  },
  {
    path: 'mapa-ronda',
    loadChildren: () => import('./AppRemu/Rondines/mapa-ronda/mapa-ronda.module').then( m => m.MapaRondaPageModule)
  },
  {
    path: 'velocidadesmap',
    loadChildren: () => import('./AppRemu/velocidadesmap/velocidadesmap.module').then( m => m.VelocidadesmapPageModule)
  },
  {
    path: 'misplanillas',
    loadChildren: () => import('./AppRemu/misplanillas/misplanillas.module').then( m => m.MisplanillasPageModule)
  },

  {
    path: 'genera-pallet',
    loadChildren: () => import('./AppCampo/genera-pallet/genera-pallet.module').then( m => m.GeneraPalletPageModule)
  },
  {
    path: 'main-campo',
    loadChildren: () => import('./AppCampo/main-campo/main-campo.module').then( m => m.MainCampoPageModule)
  },
  {
    path: 'edita-cosecha',
    loadChildren: () => import('./AppCampo/edita-cosecha/edita-cosecha.module').then( m => m.EditaCosechaPageModule)
  },
  {
    path: 'editdata',
    loadChildren: () => import('./AppCampo/edita-cosecha/editdata/editdata.module').then( m => m.EditdataPageModule)
  },
  {
    path: 'editacuartel',
    loadChildren: () => import('./AppCampo/edita-cosecha/editacuartel/editacuartel.module').then( m => m.EditacuartelPageModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./AppRemu/velocidadesmap/monitoreo/monitoreo.module').then( m => m.MonitoreoPageModule)
  },
  {
    path: 'sst-responsable',
    loadChildren: () => import('./AppRemu/sstresponsable/sst-responsable/sst-responsable.module').then( m => m.SstResponsablePageModule)
  },
  {
    path: 'existencia',
    loadChildren: () => import('./AppDirectorio/existencia/existencia.module').then( m => m.ExistenciaPageModule)
  },
  {
    path: 'palletantiguo',
    loadChildren: () => import('./AppDirectorio/palletantiguo/palletantiguo.module').then( m => m.PalletantiguoPageModule)
  },
  {
    path: 'detalle-pallet',
    loadChildren: () => import('./AppDirectorio/detalle-pallet/detalle-pallet.module').then( m => m.DetallePalletPageModule)
  },
  {
    path: 'inspeccionbuses',
    loadChildren: () => import('./AppRemu/inspeccionbuses/inspeccionbuses/inspeccionbuses.module').then( m => m.InspeccionbusesPageModule)
  },
  {
    path: 'android-estiba',
    loadChildren: () => import('./AppFrio/android-estiba/android-estiba.module').then( m => m.AndroidEstibaPageModule)
  },
  {
    path: 'main-frio',
    loadChildren: () => import('./AppFrio/main-frio/main-frio.module').then( m => m.MainFrioPageModule)
  },
  {
    path: 'hallazgos',
    loadChildren: () => import('./AppRemu/Hallazgos/hallazgos.module').then( m => m.HallazgosPageModule)

  },
  {
    path: 'permisos',
    loadChildren: () => import('./AppRemu/permisos/permisos.module').then( m => m.PermisosPageModule)
  },
  {
    path: 'editarendicion',
    loadChildren: () => import('./AppMateriales/editarendicion/editarendicion.module').then( m => m.EditarendicionPageModule)
  },
  {
    path: 'ficha-trabajador',
    loadChildren: () => import('./AppRemu/Trabajador/ficha-trabajador/ficha-trabajador.module').then( m => m.FichaTrabajadorPageModule)
  },
  {
    path: 'mis-hallazgos-responsable',
    loadChildren: () => import('./AppRemu/Hallazgos/mis-hallazgos-responsable/mis-hallazgos-responsable.module').then( m => m.MisHallazgosResponsablePageModule)
  },

  {
    path: 'consumo',
    loadChildren: () => import('./AppEstimac/consumo/consumo.module').then( m => m.ConsumoPageModule)
  },
  {
    path: 'graficos',
    loadChildren: () => import('./AppEstimac/consumo/graficos/graficos.module').then( m => m.GraficosPageModule)
  },
  {
    path: 'main-estimac',
    loadChildren: () => import('./AppEstimac/main-estimac/main-estimac.module').then( m => m.MainEstimacPageModule)

  },
  {
    path: 'listar-epp',
    loadChildren: () => import('./AppRemu/AprobarEpp/listar-epp/listar-epp.module').then( m => m.ListarEppPageModule)
  },
  {
    path: 'editar-epp',
    loadChildren: () => import('./AppRemu/AprobarEpp/editar-epp/editar-epp.module').then( m => m.EditarEppPageModule)
  },
  {
    path: 'listar-epp-detalle',
    loadChildren: () => import('./AppRemu/AprobarEpp/listar-epp-detalle/listar-epp-detalle.module').then( m => m.ListarEppDetallePageModule)
  },
  {
    path: 'app-completa',
    loadChildren: () => import('./AppEstimac/completa/completa.module').then( m => m.CompletaModule)
  },{
    path: 'consulta-stock',
    loadChildren: () => import('./AppMateriales/consulta-stock/consulta-stock.module').then( m => m.ConsultaStockPageModule)
  },
  {
    path: 'visualizarpdf',
    loadChildren: () => import('./AppMateriales/consulta-stock/visualizarpdf/visualizarpdf.module').then( m => m.VisualizarpdfPageModule)
  },
  {

    path: 'aplicacion-insumos-agricolas',
    loadChildren: () => import('./AppMateriales/aplicacion-insumos-agricolas/aplicacion-insumos-agricolas.module').then( m => m.AplicacionInsumosAgricolasPageModule)


  },
  {

    path: 'pre-frio',
    loadChildren: () => import('./AppFrio/pre-frio/pre-frio.module').then( m => m.PreFrioPageModule)


  },
  {

    path: 'firmaot',
    loadChildren: () => import('./AppMateriales/aplicacion-insumos-agricolas/firmaot/firmaot.module').then( m => m.FirmaotPageModule)

  },
  {
    path: 'firmasencargado',
    loadChildren: () => import('./AppRemu/planillasregistradas/firmasencargado/firmasencargado.module').then( m => m.FirmasencargadoPageModule)
  },
  {
    path: 'aprueba-prefrio',
    loadChildren: () => import('./AppFrio/aprueba-prefrio/aprueba-prefrio.module').then( m => m.ApruebaPrefrioPageModule)
  },
  {
    path: 'gre',
    loadChildren: () => import('./AppCampo/gre/gre.module').then( m => m.GrePageModule)
  },
  {
    path: 'agregapallet',
    loadChildren: () => import('./AppCampo/gre/agregapallet/agregapallet.module').then( m => m.AgregapalletPageModule)
  },
  {
    path: 'agregacuartel',
    loadChildren: () => import('./AppCampo/gre/agregacuartel/agregacuartel.module').then( m => m.AgregacuartelPageModule)
  },
  {
    path: 'verpdf',
    loadChildren: () => import('./AppCampo/gre/verpdf/verpdf.module').then( m => m.VerpdfPageModule)
  },
  {
    path: 'consultasiisunat',
    loadChildren: () => import('./AppCampo/gre/consultasiisunat/consultasiisunat.module').then( m => m.ConsultasiisunatPageModule)
  },
  {
    path: 'dinamica',
    loadChildren: () => import('./AppRemu/planillasregistradas/dinamica/dinamica.module').then( m => m.DinamicaPageModule)
  },
  {
    path: 'encargadobuses',
    loadChildren: () => import('./AppRemu/encargadobuses/encargadobuses.module').then( m => m.EncargadobusesPageModule)
  },
  {
    path: 'recepcion-fruta-aproceso',
    loadChildren: () => import('./AppFrio/recepcion-fruta-aproceso/recepcion-fruta-aproceso.module').then( m => m.RecepcionFrutaAProcesoPageModule)
  },
  {
    path: 'ctacteenvase',
    loadChildren: () => import('./AppCampo/gre/ctacteenvase/ctacteenvase.module').then( m => m.CtacteenvasePageModule)
  },

  {
    path: 'consulta',
    loadChildren: () => import('./proveedores/consulta/consulta.module').then( m => m.ConsultaPageModule)
  },
  {
    path: 'peso-comercial',
    loadChildren: () => import('./AppFrio/peso-comercial/peso-comercial.module').then( m => m.PesoComercialPageModule)
  },
  {
    path: 'verdocument',
    loadChildren: () => import('./AppMateriales/verdocument/verdocument.module').then( m => m.VerdocumentPageModule)
  },
  {
    path: 'auditor-bodegas',
    loadChildren: () => import('./AppMateriales/auditor-bodegas/auditor-bodegas.module').then( m => m.AuditorBodegasPageModule)
  },
  {
    path: 'imagenes-auditor-bodegas',
    loadChildren: () => import('./AppMateriales/auditor-bodegas/imagenes-auditor-bodegas/imagenes-auditor-bodegas.module').then( m => m.ImagenesAuditorBodegasPageModule)
  },
  {
    path: 'editar-auditor-bodegas',
    loadChildren: () => import('./AppMateriales/auditor-bodegas/editar-auditor-bodegas/editar-auditor-bodegas.module').then( m => m.EditarAuditorBodegasPageModule)
  },


];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})


export class AppRoutingModule { }
