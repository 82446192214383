export class PersonalizarArray
{
  public value:String=''
  public data:any[]=[]
  public length:number
  constructor(
  ){
  }

}
